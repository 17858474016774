import Service from "../Service";
const resource = "/general/";

export default {

    Search(document, requestID) {
        return Service.post(resource + "searchsunarp", "", {
            params: { document: document, requestID: requestID },
        });
    },

};