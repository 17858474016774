<template>
	<v-card flat>
			<v-row style="margin:auto" justify="center">
                <v-col cols="12" lg="12" md="12"> 
                    <v-card>
                        <v-card-text>
                            <marquee><h3><b> CONSULTA DE INFORMACION A SUNAT</b></h3></marquee>
                        </v-card-text>
                    </v-card>
                </v-col>
				<v-col cols="12" lg="3" md="3"> 

                    <s-text
                        label="Ingrese documento"
                        v-model="DocumentNumber"
                        @keyupEnter="search()"
                    >

                    </s-text>
                </v-col>
                <v-col cols="12" lg="4" md="4"> 

                   <v-btn
                        color="info"
                        style="margin-top: 10px"
                        rounded
                        @click="search()"
                   >
                       Consultar
                   </v-btn>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" lg="12" md="12">
                    <v-data-table
                        hide-default-footer
                        :items="items"
                        :headers="headers"
                        dense
                        :items-per-page="-1"
                        
                    >

                    </v-data-table>
                </v-col>
			</v-row>
	</v-card>
</template>

<script>

    import _sSunarp from "../../../services/General/SearchSunarp";
	export default {

        data(){
            return {
                items: [],
                headers: [
                    //20479555568
                    {text: "N° Documento", value: "SunNumberDocument", width: 100, sortable: false},
                    {text: "Razon Social", value: "SunName", width: 400, sortable: false},
                    /* {text: "Tipo Documento", value: "SunTypeDocument", width: 100, sortable: false}, */
                    {text: "Dirección", value: "SunAddress", width: 300, sortable: false},
                    /* {text: "Manzana", value: "SunApple", width: 100, sortable: false},
                    {text: "Numero", value: "Sunnumber", width: 100, sortable: false},
                    {text: "Dpto", value: "SunDpto", width: 100, sortable: false},
                    {text: "Interior", value: "SunInside", width: 100, sortable: false},
                    {text: "KM", value: "SunKM", width: 100, sortable: false},
                    {text: "Lote", value: "SunLot", width: 100, sortable: false}, */
                    {text: "Condicion", value: "SunCondition", width: 100, sortable: false},
                    {text: "Estado", value: "SunState", width: 100, sortable: false},
                    /* {text: "Via", value: "SunViaName", width: 100, sortable: false},
                    {text: "Tipo via", value: "SunViaType", width: 100, sortable: false}, */
                    /* {text: "Cod. Zona", value: "SunZonCode", width: 100, sortable: false}, */
                    {text: "Tipo Zona", value: "SunZonType", width: 200, sortable: false},
                    {text: "Ubigeo", value: "SunUbigeo", width: 100, sortable: false},
                    {text: "Distrito", value: "SunDistrict", width: 200, sortable: false},
                    {text: "Provincia", value: "SunProvince", width: 200, sortable: false},
                    {text: "Departamento", value: "SunDepartment", width: 200, sortable: false},
                    
                ],

                DocumentNumber: "",
            }
        },
        methods: {
            search() {
				_sSunarp
					.Search(
						this.DocumentNumber,
                        this.$fun.getUserID(),
					)
					.then(data => {
						if (data.status == 200) {
                            this.items = []
                            this.items.push(data.data)
							console.log(this.items);
						}
					});
			},
        }
    };
</script>

<style></style>
